import store from "@/store/";

import { DEFAULT_LANGUAGE } from "@/constants/language";

import { toSlug } from "@/helpers/utils";

const getTranslation = (defaultText, location, languageOverride) => {
  const language =
    languageOverride || store.state.auth.user?.private?.language || store.state.locales.language || DEFAULT_LANGUAGE;

  if (!defaultText) return "";
  if (!location) return defaultText;
  if (language === DEFAULT_LANGUAGE || language.includes(DEFAULT_LANGUAGE)) return defaultText;

  const getTranslateFromLocale = store.getters["locales/getTranslation"];

  const result = getTranslateFromLocale(language, location);

  // if (!result) console.log("Location", location);

  return result || defaultText;
};

export const stateMachineTranslater = (stateMachine, languageOverride = null) => {
  const { id, steps } = stateMachine;

  Object.keys(steps).forEach((key) => {
    const { fields } = steps[key].options;

    fields.forEach((f, fIndex) => {
      const { label = null, attributes = {} } = f;
      const { placeholder = null, options = null } = attributes;

      if (label)
        stateMachine.steps[key].options.fields[fIndex].label = getTranslation(
          label,
          `state-machine__${id}__${toSlug(label)}`,
          languageOverride
        );

      if (placeholder)
        stateMachine.steps[key].options.fields[fIndex].attributes.placeholder = getTranslation(
          placeholder,
          `state-machine__${id}__${toSlug(placeholder)}`,
          languageOverride
        );

      if (options && Array.isArray(options)) {
        options.forEach((o, oIndex) => {
          const { text } = o;
          stateMachine.steps[key].options.fields[fIndex].attributes.options[oIndex].text = getTranslation(
            text,
            `state-machine__${id}__${toSlug(text)}`,
            languageOverride
          );
        });
      }
    });
  });

  return stateMachine;
};
