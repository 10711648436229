<template>
  <b-row no-gutters>
    <b-col md="2" />

    <b-col :class="{ 'p-4': isMobile }" :style="styleWizard">
      <h1 class="mt-50 text-center text-purpink text-uppercase">
        {{ "Create your space now" | __t("projects__wizard__headline__create-your-space-now") }}
      </h1>

      <div class="border-top mt-50 mb-50" />

      <b-alert v-if="errorCode" show variant="danger" class="rounded-lg">
        <p class="mb-0">{{ "An error happened." | __t("message__an-error-happened") }} ({{ errorCode }})</p>
      </b-alert>

      <form-builder
        :state-machine="stateMachine"
        :components-list="componentsList"
        :custom-validators="{}"
        :is-sending="isSending"
        @submit="nextStep"
        class="wizard-project"
      />

      <p class="mt-4">
        {{
          "Think of a space as a digital office, a dedicated area for each one of your projects that helps the organization of Louis AI."
            | __t("projects__wizard__think-of-a-space-as-a-digital-office")
        }}
      </p>

      <ul class="mb-50">
        <li>
          <img src="@/assets/icons/check.svg" class="li-icon" />
          <strong class="text-uppercase">{{ "CLEAR CONTEXT" | __t("projects__wizard__headline__clear-context") }}</strong>
          <br />
          {{
            "Give more informations to Louis AI about a specific business. Website, social medias, and others. That way, Louis AI always have a clear understanding about what you're talking about."
              | __t("projects__wizard__give-more-informations-to-louis")
          }}
        </li>

        <li class="mt-3">
          <img src="@/assets/icons/check.svg" class="li-icon" />
          <strong class="text-uppercase">{{ "PREFERRED TASKS" | __t("projects__wizard__headline__preferred-tasks") }}</strong>
          <br />
          {{
            "Inside a space, Louis AI can accomplish the tasks you need. Whether it's writing, transcribing or brainstorming."
              | __t("projects__wizard__inside-a-space-louis-can-accomplish")
          }}
        </li>

        <li class="mt-3">
          <img src="@/assets/icons/check.svg" class="li-icon" />
          <strong class="text-uppercase">{{
            "DOCUMENT MANAGEMENT" | __t("projects__wizard__headline__document-management")
          }}</strong>
          <br />
          {{
            "Store all the documents and transcriptions that belongs to the same project. Edit and share them with ease."
              | __t("projects__wizard__store-all-the-documents-and-transcriptions")
          }}
        </li>
      </ul>
    </b-col>
  </b-row>
</template>

<script>
import { mapActions } from "vuex";
import { CREATE } from "@/store/actions";

import { FormBuilder } from "ui-stepper";
import { BRow, BCol, BAlert } from "bootstrap-vue";

import { WIZARD_STATE_MACHINE } from "@/constants/project";

import { stateMachineTranslater } from "@/helpers/uiStepper";

export default {
  name: "ProjectWizard",
  components: {
    BRow,
    BCol,
    BAlert,
    FormBuilder,
  },
  computed: {
    wizard() {
      return this.$route.query.wizard;
    },
    styleWizard() {
      const { isMobile } = this;
      if (isMobile) return "background-color: black";
      return "";
    },

    isMobile() {
      const { value } = this.$screen.alias;
      return value === "xs" || value === "sm" || value === "md";
    },
  },
  data: () => ({
    name: null,

    stateMachine: stateMachineTranslater(WIZARD_STATE_MACHINE),
    componentsList: {},

    errorCode: null,

    isSending: false,
  }),
  methods: {
    ...mapActions("projects", { createProject: CREATE }),
    async triggerCeateProject() {
      this.errorCode = null;
      this.isSending = true;

      const { name } = this;
      const { isSuccess, projectId, errorCode } = await this.createProject({ name });

      if (isSuccess && projectId) {
        this.$router.push({ name: "Project", params: { projectId }, query: { createTask: true } });
        return;
      }

      this.errorCode = errorCode;
      this.isSending = false;
    },

    nextStep() {
      const { name } = this.stateMachine.context.formData;

      this.name = name;
      this.triggerCeateProject();
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/_settings";

.wizard-project {
  .field-label {
    font-weight: 700 !important;
    text-transform: uppercase;
  }

  button {
    font-size: 1.3rem;
  }

  input {
    background-color: $black !important;
    color: white !important;
    font-size: 1.3rem;
    border-radius: 0.25rem !important;
    border: 0px !important;
  }

  input[disabled="disabled"] {
    background-color: $white-100;
    color: $gray-darker;
  }

  input:focus {
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
  }
}
</style>

<style lang="scss" scoped>
li {
  list-style: none;
  position: relative;
  padding-left: 50px;

  .li-icon {
    position: absolute;
    left: 0;
    top: 5px;
    width: 30px;
    height: 30px;
  }
}
</style>
